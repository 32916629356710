import React, { useEffect  } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TableCmd from './tableCmd.js';
import ValidationModal from './updateCmdStatusModal.js';
import DeleteModal from './deleteCmdModal.js';
import * as LocalStorage from '../services/localStorage.js';
import * as Api from "../services/quotationApi.js";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const tabTitleArray = ['Toutes les commandes', 'Brouillons', 'En attente de validation', 'En attente de diffusion', 'En cours de diffusion', 'Terminées'];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function handleCmd(id, orders) {
  let tmp = [];
  for (let i = 0; i < orders.length; i++) {
    if (id === "draftOrders") {
      if (orders[i].fk_status === "Brouillon") {
        tmp.push(orders[i]);
      }
    }
    if (id === "waitingForValidation") {
      if (orders[i].fk_status === "En attente de validation") {
        tmp.push(orders[i]);
      }
    }
    if (id === "waitingForDiffusion") {
      if (orders[i].fk_status === "En attente de diffusion") {
        tmp.push(orders[i]);
      }
    }
    if (id === "diffusing") {
      if (orders[i].fk_status === "En cours de diffusion") {
        tmp.push(orders[i]);
      }
    }
    if (id === "over") {
      if (orders[i].fk_status === "Terminée") {
        tmp.push(orders[i]);
      }
    }
  }
  return tmp;
}

export default function Content(props: TabPanelProps) {
  const [value, setValue] = React.useState(0);
  const [allOrders, setAllOrders] = React.useState(null);
  const [draftOrders, setDraftOrders] = React.useState(null);
  const [waitingForValidationOrders, setWaitingForValidationOrders] = React.useState(null);
  const [waitingForDiffusionOrders, setWaitingForDiffusionOrders] = React.useState(null);
  const [diffusingOrders, setDiffusingOrders] = React.useState(null);
  const [overOrders, setOverOrders] = React.useState(null);
  const [tabTitle, setTabtitle] = React.useState(tabTitleArray[0]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedCmdId, setSelectedCmdId] = React.useState(null);
  const [selectedCmdStatus, setSelectedCmdStatus] = React.useState(null);
  const [oldStatus, setOldStatus] = React.useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const tmp = tabTitleArray[newValue];
    setTabtitle(tmp);
  };

  useEffect( async () => {
    handleCmdRequest("cmdId")
  }, []);

  async function handleCmdRequest(specific) {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      token: token,
      email: id,
      specific: specific
    };
    const ret = await Api.getAllQuotations(data);
    if (ret !== false) {
      setAllOrders(ret.data.results.results);
      setDraftOrders(handleCmd("draftOrders", ret.data.results.results));
      setWaitingForValidationOrders(handleCmd("waitingForValidation", ret.data.results.results));
      setWaitingForDiffusionOrders(handleCmd("waitingForDiffusion", ret.data.results.results));
      setDiffusingOrders(handleCmd("diffusing", ret.data.results.results));
      setOverOrders(handleCmd("over", ret.data.results.results));
    }
    else {
      alert('Une erreur est survenue');
    }
  }

  function changeOrder(id) {
    switch (id) {
      case 0:
        setAllOrders(switchOrder(allOrders));
        break;
      case 1:
        setDraftOrders(switchOrder(draftOrders));
        break;
      case 2:
        setWaitingForValidationOrders(switchOrder(waitingForValidationOrders));
        break;
      case 3:
        setWaitingForDiffusionOrders(switchOrder(waitingForDiffusionOrders));
        break;
      case 4:
        setDiffusingOrders(switchOrder(diffusingOrders));
        break;
      case 5:
        setOverOrders(switchOrder(overOrders));
        break;
      default:
    }
  }

  function switchOrder(orders) {
    let j = orders.length - 1;
    let tmp = [];
    while (j >= 0) {
      tmp.push(orders[j]);
      j--;
    }
    return tmp;
  }

  async function updateCmdStatus(cmdId, newStatus) {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      token: token,
      email: id,
      cmdId: cmdId,
      status: newStatus
    };
    const ret = await Api.updateCmdStatus(data);
    if (ret !== false) {
      alert('Le status de la commande a été mis à jour');
      window.location.reload();
    }
    else {
      alert('Une erreur est survenue');
    }
  }

  function openModal(cmdId, cmdStatus, oldStatus) {
    setSelectedCmdId(cmdId);
    setSelectedCmdStatus(cmdStatus);
    setOldStatus(oldStatus);
    setIsModalOpen(true);
  }

  function openDeleteModal(cmdId) {
    setSelectedCmdId(cmdId);
    setIsDeleteModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function closeDeleteModal() {
    setIsDeleteModalOpen(false);
  }


  async function deleteCmd() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      token: token,
      email: id,
      cmdId: selectedCmdId
    };
    const ret = await Api.deleteCmd(data);
    if (ret !== false) {
      alert('La commande ' + data.cmdId + " a été supprimée");
      window.location.reload();
    }
    else {
      alert('Une erreur est survenue');
    }
  }

  async function initSign() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      token: token,
      email: id,
    };
    const ret = await Api.initSign(data);
    if (ret !== false) {
      alert('OKOK');
    }
    else {
      alert('Une erreur est survenue');
    }
  }

  return (
    <Paper sx={{ maxWidth: 1300, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item xs alignItems="space-between">
              <Typography color="text.secondary" align="right" style={{fontWeight: "bold"}}>{tabTitle}</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: '100%' }} style={{display: "flex", flexDirection: "row"}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider'}} style={{ borderRight: "1px solid #eaeff1"}}>
          <Tabs value={value} onChange={handleChange} aria-label="commandes status" orientation="vertical">
            <Tab label={tabTitleArray[0]} {...a11yProps(0)} style={{width: "max-content"}}/>
            <Tab label={tabTitleArray[1]} {...a11yProps(1)} style={{width: "max-content"}}/>
            <Tab label={tabTitleArray[2]} {...a11yProps(2)} style={{width: "max-content"}}/>
            <Tab label={tabTitleArray[3]} {...a11yProps(3)} style={{width: "max-content"}}/>
            <Tab label={tabTitleArray[4]} {...a11yProps(4)} style={{width: "max-content"}}/>
            <Tab label={tabTitleArray[5]} {...a11yProps(4)} style={{width: "max-content"}}/>
          </Tabs>
        </Box>
        <div style={{display: "flex", justifyContent: "center", width: "80%", height: "75vh"}}>
          <TabPanel value={value} index={0} style={{width: "100%"}}>
            <TableCmd orders={allOrders} index={0} handleCmdRequest={handleCmdRequest} changeOrder={changeOrder} openModal={openModal} openDeleteModal={openDeleteModal}/>
          </TabPanel>
          <TabPanel value={value} index={1} style={{width: "100%"}}>
            <TableCmd orders={draftOrders} index={1} handleCmdRequest={handleCmdRequest} changeOrder={changeOrder} updateCmdStatus={updateCmdStatus} openDeleteModal={openDeleteModal}/>
          </TabPanel>
          <TabPanel value={value} index={2} style={{width: "100%"}}>
            <TableCmd orders={waitingForValidationOrders} index={2} handleCmdRequest={handleCmdRequest} changeOrder={changeOrder} updateCmdStatus={updateCmdStatus} openDeleteModal={openDeleteModal}/>
          </TabPanel>
          <TabPanel value={value} index={3} style={{width: "100%"}}>
            <TableCmd orders={waitingForDiffusionOrders} index={3} handleCmdRequest={handleCmdRequest} changeOrder={changeOrder} updateCmdStatus={updateCmdStatus} openDeleteModal={openDeleteModal}/>
          </TabPanel>
          <TabPanel value={value} index={4} style={{width: "100%"}}>
            <TableCmd orders={diffusingOrders} index={4} handleCmdRequest={handleCmdRequest} changeOrder={changeOrder} updateCmdStatus={updateCmdStatus} openDeleteModal={openDeleteModal}/>
          </TabPanel>
          <TabPanel value={value} index={5} style={{width: "100%"}}>
            <TableCmd orders={overOrders} index={5} handleCmdRequest={handleCmdRequest} changeOrder={changeOrder} updateCmdStatus={updateCmdStatus} openDeleteModal={openDeleteModal}/>
          </TabPanel>
        </div>
      </Box>
      <ValidationModal open={isModalOpen} closeModal={closeModal} selectedCmdId={selectedCmdId} selectedCmdStatus={selectedCmdStatus} oldStatus={oldStatus} updateCmdStatus={updateCmdStatus}/>
      <DeleteModal open={isDeleteModalOpen} closeModal={closeDeleteModal} selectedCmdId={selectedCmdId} deleteCmd={deleteCmd}/>
    </Paper>
  );
}

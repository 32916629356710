import React, { useEffect, useState  } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import UpdateUserModal from './updateUserModal.js';
import ConfirmationModal from './updateUserConfirmationModal.js';
import * as LocalStorage from '../services/localStorage.js';
import * as Api from "../services/userApi.js";

function createData(
  id: number,
  company: string,
  firstName: string,
  lastName: string,
  email: string,
  icon: number,
) {
  return { id, company, firstName, lastName, email, icon };
}

export default function Content() {
  const [rows, setRows] = React.useState([]);
  const [selectedUserId, setSelectedUserId] = React.useState(-1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userType, setUserType] = React.useState(0);
  const [company, setCompany] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [addr, setAddr] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);

  useEffect( async () => {
    getUserList();
  }, []);

  function handleAddr(addr) {
    if (addr.includes("undefined") === true) {
      return "";
    }
    return addr;
  }

  function getOpenButton(id, isAgency, firstName, lastName, company, email, phoneNumber, addr, userId) {
    return <div><Button onClick={() => {
      setSelectedUserId(id);
      setUserType(isAgency === true ? 1 : 0);
      setFirstName(firstName);
      setLastName(lastName);
      setCompany(company);
      setEmail(email);
      setPhoneNumber(phoneNumber);
      setAddr(handleAddr(addr));
      setUserId(userId)
      openModal(true);
    }}>Modifier</Button></div>
  }

  function createRowItem(id, company, firstName, lastName, email, isAgency, phoneNumber, addr, userId) {
    return createData(id, company, firstName, lastName, email, getOpenButton(id, isAgency, firstName, lastName, company, email, phoneNumber, addr, userId));
  }

  function openModal(id) {
    setSelectedUserId(id);
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function openConfirmModal() {
    setIsConfirmModalOpen(true);
  }

  function openDeleteModal() {
    setIsDeleteModal(true);
    setIsConfirmModalOpen(true);
  }

  function closeConfirmModal() {
    setIsDeleteModal(false);
    setIsConfirmModalOpen(false);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleUserTypeChange(value) {
    //0 Annonceur 1 agence
    setUserType(value);
  }

  //general function used to update state
  function updateState(stateName, value) {
    switch (stateName) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "company":
        setCompany(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      default:

    }
  }

  function checkForFields() {
    if (email === "" || firstName === "" || lastName === "" || company === "" || phoneNumber === "") {
      return false;
    }
    return true;
  }

  async function deleteUser() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();

    const data = {
      email: id,
      token: token,
      userId: userId,
      userEmail: email
    };
    const ret = await Api.deleteUser(data);
    if (ret !== false) {
      setIsConfirmModalOpen(false);
      setIsModalOpen(false);
      alert("L'utilisateur a été supprimé.");
      window.location.reload();
    }
    //update user
    else {
      setIsConfirmModalOpen(false);
      setIsModalOpen(false);
      alert("Une erreur est survenue.");
    }
  }

  async function updateUser() {
    if (checkForFields() === false) {
      alert("Merci de remplir l'ensemble des champs du formulaire");
      return;
    }

    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      email: id,
      token: token,
      firstName: firstName,
      lastName: lastName,
      company: company,
      userEmail: email,
      phoneNumber: phoneNumber,
      isAgency: userType === 0 ? false : true,
      userId: userId
    };
    const ret = await Api.updateUser(data);
    if (ret !== false) {
      setIsConfirmModalOpen(false);
      setIsModalOpen(false);
      alert("La modification de l'utilisateur a été réalisée avec succès.");
      window.location.reload();
    }
    else {
      setIsConfirmModalOpen(false);
      setIsModalOpen(false);
      alert("Une erreur est survenue.");
    }
  }

  async function getUserList() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      email: id,
      token: token,
    };
    const ret = await Api.listUser(data);
    if (ret !== false) {
      let tmp = [];
      for (let i = 0; i < ret.length; i++) {
        tmp.push(createRowItem(i, ret[i].company, ret[i].firstName, ret[i].lastName, ret[i].email, ret[i].isAgency, ret[i].phoneNumber, ret[i].addrNumber + " " + ret[i].addrStreet + " " + ret[i].addrPostalCode + " " + ret[i].addrCity, ret[i]._id));
      }
      setRows(tmp);
    }
    else {
      alert("Une erreur est survenue.");
    }
  }

  return (
    <Paper sx={{ maxWidth: 1300, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography color="text.secondary" align="right" style={{fontWeight: "bold"}}>Liste des utilisateurs</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <TableContainer component={Paper} style={{maxHeight: "70vh"}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{fontWeight: "bold"}}>Société</TableCell>
              <TableCell align="left" style={{fontWeight: "bold"}}>Nom</TableCell>
              <TableCell align="left" style={{fontWeight: "bold"}}>Prénom</TableCell>
              <TableCell align="left" style={{fontWeight: "bold"}}>Email</TableCell>
              <TableCell align="right" style={{fontWeight: "bold"}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              typeof rows !== "undefined"
              ?
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={index % 2 === 0 ? {backgroundColor: "#DCDCDC"} : {backgroundColor: "white"}}>
                    <TableCell align="left">{row.company}</TableCell>
                    <TableCell align="left">{row.lastName}</TableCell>
                    <TableCell align="left">{row.firstName}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="right">{row.icon}</TableCell>
                  </TableRow>
              ))
              :
              null
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Utilisateurs par page"
      />
      <UpdateUserModal open={isModalOpen} closeModal={closeModal} openDeleteModal={openDeleteModal} openConfirmModal={openConfirmModal} isLoading={isLoading} userType={userType} company={company} lastName={lastName} firstName={firstName} email={email} phoneNumber={phoneNumber} addr={addr} updateState={updateState} handleUserTypeChange={handleUserTypeChange}/>
      <ConfirmationModal open={isConfirmModalOpen} isDeleteModal={isDeleteModal} closeModal={closeConfirmModal} updateUser={updateUser} deleteUser={deleteUser}/>
    </Paper>
  );
}

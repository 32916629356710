import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useParams } from "react-router-dom";

import * as LocalStorage from './localStorage';
import * as UserApi from './userApi';
import Home from '../pages/home.js';
import NewCmd from '../pages/newCmd.js';
import Login from '../pages/login.js';
import ForgotPassword from '../pages/ForgotPassword';
import NewPassword from '../pages/NewPassword';
import ListUser from '../pages/listUser';
import ListConnection from '../pages/listConnection';
import AddUser from '../pages/addUser';
import AdminDb from '../pages/adminDb';

const routeAllowedNoAuth = [
  "recuperation-mot-de-passe",
  "nouveau-mot-de-passe",
];

//const host1 = 'https://www.videoclub.pawlaczyk.dev';
//const host2 = 'https://videoclub.pawlaczyk.dev';

const host1 = 'https://admin.pawlaczyk.dev/';
const host2 = 'admin.pawlaczyk.dev/';

function RoutesManager() {
  const [isUserConnected, setIsUserConnected] = useState(false);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    //used to check token validity
    const checkToken = async(data) => {
      const ret = await UserApi.handleSessionToken(data);
      if (ret !== false) {
        let route = window.location.href.replace(host1, '');
        route = window.location.href.replace(host2, '');
        if (route.includes("/connexion")) {
          setIsUserConnected(true);
        }
        else {
          if (route.includes('accueil')) {
            navigate("/accueil");
          }
          else if (route.includes('commandes')) {
            navigate("/commandes");
          }
          else if (route.includes('listConnection')) {
            navigate("/listConnection");
          }
          else if (route.includes('listUser')) {
            navigate("/listUser");
          }
          else if (route.includes('adminDb')) {
            navigate("/adminDb");
          }
        }
      }
      else {
        navigate("/connexion");
      }
    }
    //user connection success
    if (isUserConnected) {
      navigate("/");
    }
    //user not connected yet
    else if (!isUserConnected) {
      if (isRouteAllowed()) {
        return;
      }
      const token = LocalStorage.getToken();
      const id = LocalStorage.getId();
      if (token === -1) {
        navigate("/connexion");
      }
      else {
        const data = {
          token: token,
          userId: id,
        }
        //try to log user thanks to token
        checkToken(data);
      }
    }
  }, [isUserConnected]);

  function isRouteAllowed() {
    let routesArray = routeAllowedNoAuth;
    const route = window.location.href;
    for (let i = 0; i < routesArray.length; i++) {
      if (route.includes(routesArray[i])) {
        return true;
      }
    }
    return false;
  }

  function handleUserConnection() {
    const newState = !isUserConnected;
    setIsUserConnected(newState);
    if (newState === false) {
      LocalStorage.resetToken();
    }
  }

  function disconnectUser() {
    setIsUserConnected(false);
    LocalStorage.resetToken();
    navigate('/');
  }


  function handleUserId(id) {
    setUserId(id);
  }

  function NewPasswordWrapper() {
    let params = useParams();
    return <NewPassword email={params.account} token={params.token}/>;
  }

    return(
      <div className="App">
        <Routes basename={'/backoffice_videoclub-pub'}>
          <Route path="/" element={isUserConnected ?  <Navigate to="/accueil"/> : <Navigate to="/connexion"/>} />
          <Route path="connexion"
            element={
              <Login
                handleUserConnection={() => handleUserConnection()}
                handleUserId={(id) => handleUserId(id)}
              />
            }/>
          <Route path="accueil" element={<Home />}/>
          <Route path="commandes" element={<NewCmd />}/>
          <Route path="listUser" element={<ListUser />}/>
          <Route path="addUser" element={<AddUser />}/>
          <Route path="adminDb" element={<AdminDb />}/>
          <Route path="listConnection" element={<ListConnection />}/>
          <Route path="recuperation-mot-de-passe" element={<ForgotPassword />}/>
          <Route path="nouveau-mot-de-passe/:account/:token" element={<NewPasswordWrapper />}/>
        </Routes>
      </div>
    );
}

export default RoutesManager;

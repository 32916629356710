import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import RefreshIcon from '@mui/icons-material/Refresh';
import Link from '@mui/material/Link';

export default function Content(props) {
  return (
    <Paper sx={{ maxWidth: 1300, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography color="text.secondary" align="left" style={{fontWeight: "bold", fontSize: "1.2em", marginBottom: "2em", marginTop: "1em"}}>Nouvel utilisateur</Typography>
              <form id="" className="" onSubmit={e => e.preventDefault()}>
                <div style={{marginBottom: '1em'}}>
                  <FormControl fullWidth>
                    <InputLabel id="">Type d'utilisateur</InputLabel>
                    <Select
                      labelId=""
                      id=""
                      value={props.userType}
                      label="Type d'utilisateur"
                      onChange={(e) => props.handleUserTypeChange(e.target.value)}
                      style={{marginBottom: "0.5em"}}
                    >
                      <MenuItem value={0}>Annonceur</MenuItem>
                      <MenuItem value={1}>Agence</MenuItem>
                    </Select>
                  <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                    <TextField style={{width: "100%"}} id="" placeholder="Société"  onChange={(e) => props.updateState("company", e.target.value)}/>
                  </div>
                  <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                    <TextField style={{width: "100%"}} id="" placeholder="Nom" value={props.lastName} onChange={(e) => props.updateState("lastName", e.target.value)}/>
                  </div>
                  <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                    <TextField style={{width: "100%"}} id="" placeholder="Prénom" value={props.firstName} onChange={(e) => props.updateState("firstName", e.target.value)}/>
                  </div>
                  <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                    <TextField style={{width: "100%"}} id="" placeholder="Email" value={props.email} onChange={(e) => props.updateState("email", e.target.value)}/>
                  </div>
                  <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                    <TextField style={{width: "100%"}} id="" placeholder="Téléphone" value={props.phoneNumber} onChange={(e) => props.updateState("phoneNumber", e.target.value)}/>
                  </div>
                  <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                    <Typography color="text.secondary" align="left" style={{fontWeight: "bold"}}>Mot de passe : @123Quatre</Typography>
                  </div>
                  <div style={{marginTop: "0.5em", marginBottom: '0.5em', display: "flex", justifyContent: "flex-end"}}>
                    {
                      props.isLoading === false
                      ?
                      <Button onClick={props.createUser}>Créer l'utilisateur</Button>
                      :
                      <p>Chargement ...</p>
                    }
                  </div>
                </FormControl>
                </div>
              </form>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>


    </Paper>
  );
}

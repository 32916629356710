import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../assets/img/logo.png';
import { useNavigate } from "react-router-dom";
import {withRouter} from '../services/withRouter.js';
import * as Api from '../services/userApi.js';
import * as LocalStorage from '../services/localStorage';
import "../css/app.css";


const theme = createTheme();

function ForgotPassword() {
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    const tmp = new FormData(event.currentTarget);
    const data = {
      email: tmp.get('email')
    };
    const ret = await Api.resetPassword(data);
    if (ret === true) {
      alert("Un email vous a été envoyé pour changer votre mot de passe");
      navigate('/connexion');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" style={{border: "1px solid black", marginTop: "10%", borderRadius: "5%"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'black', height: "100px", width: "100px"}}>
            <img alt="logo" src={Logo} style={{width: "100px", height: "auto"}}/>
          </Avatar>
          <Typography component="h1" variant="h6" style={{marginTop: "0.5em"}}>
            <span style={{fontWeight: "bold"}}>Mot de passe oublié ?</span>
          </Typography>
          <Box  style={{marginTop: "1em"}} component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <span>Merci de reseigner l'adresse email de votre compte administrateur avant de valider.</span>
            <TextField
              style={{marginTop: "2em"}}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              style={{marginBottom: "2em"}}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Changer mon mot de passe
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default withRouter(ForgotPassword);

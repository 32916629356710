import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../assets/img/logo.png';
import { useNavigate } from "react-router-dom";
import * as Api from '../services/userApi.js';
import * as LocalStorage from '../services/localStorage';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <span style={{fontSize: "1.2em"}}>© Vidéoclub-pub</span>
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    const credentials = new FormData(event.currentTarget);
    const data = {
      email: credentials.get('email'),
      password: credentials.get('password')
    }
    const ret = await Api.logUserIn(data);
    //console.log(ret.status)
    if (ret.status === 401) {
      alert("Adresse email et mot de passe ne correspondent pas");
    }
    else if (ret.status === 500) {
      alert('Unexpected error');
    }
    //connection succed
    else if (ret.status === 200) {
      LocalStorage.setToken(ret.token);
      LocalStorage.setId(credentials.get('email'));
      navigate('/accueil');
    }

  };

  function goToForgotPassword() {
    navigate('/recuperation-mot-de-passe');
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" style={{border: "1px solid black", marginTop: "10%", borderRadius: "5%"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'black', height: "100px", width: "100px"}}>
            <img alt="logo" src={Logo} style={{width: "100px", height: "auto"}}/>
          </Avatar>
          <Typography component="h1" variant="h6" style={{marginTop: "0.5em"}}>
            <span style={{fontWeight: "bold"}}>Back-Office</span>
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Connexion
            </Button>
            <Grid container>
             <Grid item xs style={{display: "flex", justifyContent: "flex-end"}}>
               <Link href="#" variant="body2">
                 <span onClick={() => goToForgotPassword()}>Mot passe oublié ?</span>
               </Link>
             </Grid>
           </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 4, mb: 2 }} />
      </Container>
    </ThemeProvider>
  );
}

import React, { useState, useEffect  } from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PublicIcon from '@mui/icons-material/Public';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import TimerIcon from '@mui/icons-material/Timer';
import SettingsIcon from '@mui/icons-material/Settings';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import LogoutIcon from '@mui/icons-material/Logout';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import Modal from "./saveDbModal.js";
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import * as LocalStorage from '../services/localStorage.js';
import * as Api from "../services/quotationApi.js";
import * as UserApi from "../services/UserApi.js";
import { useNavigate } from "react-router-dom";

const categories = [
  {
    id: 'Analyse',
    children: [
      {
        id: 'Statistiques du site',
        icon: <QueryStatsIcon />,
        active: false,
      },
    ],
  },
  {
    id: 'Commandes',
    children: [
      {
        id: 'Voir les commandes',
        icon: <ShoppingCartCheckoutIcon />,
        active: false,
      },
    ],
  },
  {
    id: 'Utilisateurs',
    children: [
      {
        id: 'Liste des utilisateurs',
        icon: <GroupIcon />,
        active: false,
      },
      {
        id: 'Ajouter un utilisateur',
        icon: <GroupAddIcon />,
        active: false,
      },
      {
        id: 'Connexions utilisateurs',
        icon: <SensorOccupiedIcon />,
        active: false,
      },
    ],
  },
  {
    id: 'Divers',
    children: [
      { id: 'Savegarder la base de données', icon: <DnsRoundedIcon /> },
      { id: 'Déconnexion', icon: <LogoutIcon /> }
    ],
  },
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const [currentPage, setCurrentPage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isMe, setIsMe] = React.useState(false);
  const navigate = useNavigate();

  async function initiate() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      token: token,
      email: id,
    };
    const ret = await UserApi.checkIfMe(data);
    if (ret !== false) {
      if (ret === "true") {
        setIsMe(true);
      }
      else {
        setIsMe(false);
      }
    }
    else {
      //alert('Une erreur est survenue');
    }
  }

  async function saveDatabase() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      token: token,
      email: id,
    };
    const ret = await Api.saveDatabase(data);
    if (ret !== false) {
      handleOpen();
    }
    else {
      alert('Une erreur est survenue');
    }
  }

  useEffect(() => {
    initiate();
  }, []);

  const { ...other } = props;

  function decoUser() {
    navigate('/connexion');
    LocalStorage.resetToken();
    window.location.reload();
  }

  function handleMenuClick(id) {
    switch (id) {
      case "Statistiques du site" :
        navigate('/accueil');
        window.location.reload();
        break;
      case "Voir les commandes" :
        navigate('/commandes');
        window.location.reload();
        break;
      case "Liste des utilisateurs":
        navigate('/listUser');
        window.location.reload();
        break;
      case "Ajouter un utilisateur":
        navigate("/addUser");
        window.location.reload();
        break;
      case "Savegarder la base de données" :
        saveDatabase();
        break;
      case "Connexions utilisateurs":
        navigate("/listConnection");
        window.location.reload();
        break;
      case "Déconnexion":
        decoUser();
        break;
      default:
    }
  }

  async function saveDatabaseEmail() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      token: token,
      email: id,
    };
    const ret = await Api.saveDatabaseEmail(data);
    if (ret !== false) {
      setOpen(false);
      alert('La sauvegarde de la base de données vous a été envoyé par email');
    }
    else {
      setOpen(false);
      alert('Une erreur est survenue');
    }
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          Back-Office
        </ListItem>
        <ListItem sx={{ ...item, ...itemCategory }} style={{cursor: "pointer"}}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText onClick={() => window.open("https://videoclub-pub.app", '_blank')}>Vidéoclub-pub.app</ListItemText>
        </ListItem>
        {
          isMe === true ?
          <div>
            <ListItem sx={{ ...item, ...itemCategory }} style={{cursor: "pointer"}}>
              <ListItemIcon>
                <DeveloperModeIcon />
              </ListItemIcon>
              <ListItemText onClick={() => {
                navigate("/adminDb");
                window.location.reload();
              }}>Administration base de données</ListItemText>
            </ListItem>
          </div>
          :
          null
        }
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton selected={false} sx={item} onClick={() => handleMenuClick(childId)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}

            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
      <Modal open={open} handleClose={handleClose} saveDatabaseEmail={saveDatabaseEmail}/>
    </Drawer>
  );
}

const ApiAddr = initApiAddr();

function initApiAddr() {
  let ret = "";
  if (window.location.href.includes('pawlaczyk.dev')) {
    ret = "https://api.pawlaczyk.dev/api/admin";
  }
  else {
    ret = "https://api.videoclub-pub.app/api/admin";
  }
  //console.log('API ADDR')
  //console.log(ret)
  return ret;
}

export {ApiAddr};

import React, { useState, useEffect  } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal(props) {

  const [isLoading, setIsLoading] = React.useState(false);

  async function handleSaveDb() {
    setIsLoading(true);
    await props.saveDatabaseEmail();
    setIsLoading(false);
  }


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={() => props.handleClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            {
              <span className="bold">Sauvegarde de la base de données</span>
            }
            </Typography>
            <div style={{marginTop: "1.5em", marginBottom: "1.5em"}}>
              <span style={{display: "flex", flexDirection: "column"}}>
                <Typography sx={{ mt: 2 }}>
                  <span className="">La base de données a bien été sauvegardée sur le serveur.</span>
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  <span className="">Pour plus de sécurité, vous pouvez recevoir la sauvegarde par email en cliquant sur le lien ci-dessous.</span>
                </Typography>
                <div style={{marginTop: "1em"}}>
                {
                  isLoading === true
                  ?
                  <span style={{color: "blue"}}>Chargement ...</span>
                  :
                  <Button onClick={() => handleSaveDb()}><span style={{fontSize: "1.1em"}}>Envoyer par email</span></Button>
                }
                </div>
              </span>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30vw",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal(props) {

  function getCmdStatus(cmdStatus) {
    switch (cmdStatus) {
      case "1":
        return "Brouillon";
        break;
      case "2":
        return "En attente de validation";
        break;
      case "3":
        return "En attente de diffusion";
        break;
      case "4":
        return "En cours de diffusion";
        break;
      case "5":
        return "Terminée";
        break;
      default:
        return cmdStatus;
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={props.closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            {
              <span className="bold">Confirmation de changement de statut</span>
            }
            </Typography>
            <div style={{marginTop: "1.5em", marginBottom: "1.5em"}}>
              <Typography sx={{ mt: 2 }}>
                <span className="">Voulez vous changer le statut de la commande <span style={{fontWeight:"bold"}}>{props.selectedCmdId}</span> ? </span>
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <span style={{fontWeight:"bold"}}>Ancien statut</span> : {props.oldStatus}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <span style={{fontWeight:"bold"}}>Nouveau statut</span> : {getCmdStatus(props.selectedCmdStatus)}
              </Typography>
              <div style={{position: "relative", marginTop: "2em", marginBottom: "-1em", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Button variant="contained" onClick={() => props.updateCmdStatus(props.selectedCmdId, props.selectedCmdStatus)} style={{width: "48%"}}>Confirmer</Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import axios from 'axios';
import { ApiAddr } from './constant.js';

const apiAddr = ApiAddr;

//get all quotations
export async function getAllQuotations(data) {
  try {
    const ret = await axios.post(apiAddr+'/getAllQuotations', data);
    console.log(ret);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

export async function saveDatabase(data) {
  try {
    const ret = await axios.post(apiAddr+'/saveDatabase', data);
    console.log(ret);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

export async function saveDatabaseEmail(data) {
  try {
    const ret = await axios.post(apiAddr+'/saveDatabaseEmail', data);
    console.log(ret);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

export async function updateCmdStatus(data) {
  try {
    const ret = await axios.post(apiAddr+'/updateCmdStatus', data);
    console.log(ret);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

export async function initSign(data) {
  try {
    const ret = await axios.post(apiAddr+'/initSign', data);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

export async function deleteCmd(data) {
  try {
    const ret = await axios.post(apiAddr+'/deleteCmd', data);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

export async function getLogs(data) {
  try {
    const ret = await axios.post(apiAddr+'/getLogs', data);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

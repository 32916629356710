import React, { useState, useEffect  } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import Link from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MetricsContent from "./metricsContent.js";
import * as Api from "../services/quotationApi.js";
import * as LocalStorage from '../services/localStorage.js';

const isDev = true;

export default function Content() {
  const [value, setValue] = React.useState(0);
  const [oneDay, setOneday] = React.useState({});
  const [threeDays, setThreeDays] = React.useState({});
  const [sevenDays, setSevenDays] = React.useState({});
  const [thirtyDays, setThirtyDays] = React.useState({});
  const [sixtyDays, setSixtyDays] = React.useState({});
  const [ninetyDays, setNinetyDays] = React.useState({});

  useEffect( async () => {
    getLogs('oneDay');
  }, []);

  async function getLogs(period) {
    //check if logs must be retreave
    if (logMustBeRetreave(period) === false) {
      return;
    }
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      email: id,
      token: token,
      period: period
    };
    const ret = await Api.getLogs(data);
    if (ret !== false) {
      handleResults(period, ret.data);
    }
    else {
      alert("Une erreur est survenue lors de la récupération des données.");
    }
  }

  //function used to get log only once
  function logMustBeRetreave(object) {
    if (object.connections !== undefined) {
      return false;
    }
    return true;
  }

  function handleResults(period, results) {
    switch (period) {
      case "oneDay":
        setOneday({
          connections: results.connections,
          uniqueConnections: results.uniqueConnections,
          cmd: results.cmd,
          draftCmd: results.draftCmd,
          browserArray: results.browserArray,
          osArray: results.osArray
        });
        break;
      case "threeDays":
        setThreeDays({
          connections: results.connections,
          uniqueConnections: results.uniqueConnections,
          cmd: results.cmd,
          draftCmd: results.draftCmd,
          browserArray: results.browserArray,
          osArray: results.osArray
        });
        break;
      case "sevenDays":
        setSevenDays({
          connections: results.connections,
          uniqueConnections: results.uniqueConnections,
          cmd: results.cmd,
          draftCmd: results.draftCmd,
          browserArray: results.browserArray,
          osArray: results.osArray
        });
        break;
        case "thirtyDays":
          setThirtyDays({
            connections: results.connections,
            uniqueConnections: results.uniqueConnections,
            cmd: results.cmd,
            draftCmd: results.draftCmd,
            browserArray: results.browserArray,
            osArray: results.osArray
          });
          break;
        case "sixtyDays":
          setSixtyDays({
            connections: results.connections,
            uniqueConnections: results.uniqueConnections,
            cmd: results.cmd,
            draftCmd: results.draftCmd,
            browserArray: results.browserArray,
            osArray: results.osArray
          });
          break;
        case "ninetyDays":
          setNinetyDays({
            connections: results.connections,
            uniqueConnections: results.uniqueConnections,
            cmd: results.cmd,
            draftCmd: results.draftCmd,
            browserArray: results.browserArray,
            osArray: results.osArray
          });
          break;
      default:
      return;
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function getValueForPeriod(value) {
    switch (value) {
      case 0:
        if (logMustBeRetreave(oneDay) === true) {
          getLogs('oneDay');
        }
        return oneDay;
        break;
      case 1:
        if (logMustBeRetreave(threeDays) === true) {
          getLogs('threeDays');
        }
        return threeDays;
        break;
      case 2:
        if (logMustBeRetreave(sevenDays) === true) {
          getLogs('sevenDays');
        }
        return sevenDays;
        break;
      case 3:
        if (logMustBeRetreave(thirtyDays) === true) {
          getLogs('thirtyDays');
        }
        return thirtyDays;
        break;
      case 4:
        if (logMustBeRetreave(sixtyDays) === true) {
          getLogs('sixtyDays');
        }
        return sixtyDays;
        break;
      case 5:
        if (logMustBeRetreave(ninetyDays) === true) {
          getLogs('ninetyDays');
        }
        return ninetyDays;
        break;
      default:
        return;
    }
  }

  return (
    <Paper sx={{ maxWidth: 1300, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography color="text.secondary" align="right" style={{fontWeight: "bold"}}>Statistiques générales sur l'application videoclub-pub</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {
        isDev === true
        ?
        <div>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
                <Tab label="Aujourd'hui"/>
                <Tab label="3 jours" />
                <Tab label="7 jours" />
                <Tab label="30 jours" />
                <Tab label="60 jours" />
                <Tab label="90 jours" />
              </Tabs>
            </Box>
            <div value={value}>
              <MetricsContent data={getValueForPeriod(value)}/>
            </div>
          </Box>
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            Retrouvez l'ensemble des statistiques sur le site de <Link href="https://clarity.microsoft.com/" target="_blank" variant="body2"><span style={{fontWeight: "bold"}}>Clarity</span></Link>
          </Typography>
        </div>
        :
        <div>
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            <span style={{fontWeight: "bold"}}>Les statistiques ne sont pas encore implémentées sur le back-office.</span>
          </Typography>
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            Retrouvez l'ensemble des statistiques sur le site de <Link href="https://clarity.microsoft.com/" target="_blank" variant="body2"><span style={{fontWeight: "bold"}}>Clarity</span></Link>
          </Typography>
        </div>
      }
    </Paper>
  );
}

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30vw",
  minWidth: '550px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal(props) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={props.closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            {
              <span className="bold">Confirmation</span>
            }
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <span className="">Voulez vous vraiment supprimer la commande <span style={{fontWeight: "bold"}}>{props.selectedCmdId}</span> ?</span>
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <span style={{color: "red", fontWeight: "bold"}}>Cette action est irréversible</span>
            </Typography>
            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "2em"}}>
              <Button color="error" onClick={props.closeModal}>Annuler</Button>
              <Button onClick={props.deleteCmd}>Valider</Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

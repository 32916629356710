import axios from 'axios';
import{ ApiAddr } from './constant.js';

const apiAddr = ApiAddr;

//User login api call
export async function logUserIn(data) {
  try {
     const res = await axios.post(apiAddr+'/login', data);
     return {
       status: 200,
       token: res.data.token,
     };
   }
   catch(error) {
     console.log(error);
     if (error.toString().includes('401')) {
       return {
         error: error.response.data.error,
         status: 401,
       };
     }
     else if (error.toString().includes('500')) {
       //Unexpected error
       return { status: 500 };
     }
   }
}

//api call to reset user password
export async function resetPassword(data) {
  try {
    const res = await axios.post(apiAddr+'/resetPassword', data);
    //handle user api errors
    if (res.data.error) {
      return false;
    }
    return true;
  }
  catch(error) {
    console.log('Error with function resetPassword : ', error);
    return false;
  }
}

//api call to reset user password
export async function newPassword(data) {
  try {
    await axios.post(apiAddr+'/newPassword', data);
    return true;
  }
  catch(error) {
    console.log('Error with function newPassword : ', error);
    return false;
  }
}


//api call to check and update user session token
export async function handleSessionToken(data) {
  try {
    const res = await axios.post(apiAddr+'/handleTokenVerification', data);
    //handle user api errors
    if (res.data.error) {
      return false;
    }
    return res.data.message;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function handleSessionToken : ', error);
    return false;
  }
}

//api call to add new user
export async function addNewUser(data) {
  try {
    const res = await axios.post(apiAddr+'/addNewUser', data);
    //handle user api errors
    if (res.data.error) {
      console.log(res.data.error);
      return false;
    }
    return res.data.message;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function addNewUser : ', error);
    return false;
  }
}

//api call to add update existant user
export async function updateUser(data) {
  try {
    const res = await axios.post(apiAddr+'/updateUser', data);
    //handle user api errors
    if (res.data.error) {
      console.log(res.data.error);
      return false;
    }
    return res.data.message;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function addNewUser : ', error);
    return false;
  }
}

//api call to delete existant user
export async function deleteUser(data) {
  try {
    const res = await axios.post(apiAddr+'/deleteUser', data);
    //handle user api errors
    if (res.data.error) {
      console.log(res.data.error);
      return false;
    }
    return res.data.message;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function addNewUser : ', error);
    return false;
  }
}

//api call to add new user
export async function listUser(data) {
  try {
    const res = await axios.post(apiAddr+'/listUser', data);
    //handle user api errors
    if (res.data.error) {
      console.log(res.data.error);
      return false;
    }
    return res.data.message;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function addNewUser : ', error);
    return false;
  }
}

//api call to add new user
export async function checkIfMe(data) {
  try {
    const res = await axios.post(apiAddr+'/checkIfMe', data);
    //handle user api errors
    if (res.data.error) {
      console.log(res.data.error);
      return false;
    }
    return res.data.message;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function addNewUser : ', error);
    return false;
  }
}

//api call to list all sql users
export async function listSqlUser(data) {
  try {
    const res = await axios.post(apiAddr+'/listSqlUser', data);
    //handle user api errors
    if (res.data.error) {
      console.log(res.data.error);
      return false;
    }
    return res.data.results;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function listSqlUser : ', error);
    return false;
  }
}

//api call to delete user
export async function deleteSqlUser(data) {
  try {
    const res = await axios.post(apiAddr+'/deleteSqlUser', data);
    //handle user api errors
    if (res.data.error) {
      console.log(res.data.error);
      return false;
    }
    return true;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function listSqlUser : ', error);
    return false;
  }
}

//api call to add new sql user
export async function addNewSqlUser(data) {
  try {
    const res = await axios.post(apiAddr+'/addNewSqlUser', data);
    //handle user api errors
    if (res.data.error) {
      console.log(res.data.error);
      return false;
    }
    return true;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function addNewSqlUser : ', error);
    return false;
  }
}

import React, { useEffect, useState  } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ConfirmationModal from './deleteSqlUserModal.js';
import * as LocalStorage from '../services/localStorage.js';
import * as Api from "../services/userApi.js";

function createData(
  id: number,
  email: string,
  icon: string,
) {
  return { id, email, icon };
}

export default function Content() {
  const [rows, setRows] = React.useState([]);
  const [selectedUserId, setSelectedUserId] = React.useState(-1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [newUserEmail, setNewUserEmail] = React.useState("");

  useEffect( async () => {
    getUserList();
  }, []);

  function getOpenButton(id, email) {
    return <div><Button style={{color: "red", fontWeight: "bold"}} onClick={() => {
      setSelectedUserId(id);
      setEmail(email);
      setIsDeleteModalOpen(true);
    }}>Supprimer</Button></div>
  }

  function createRowItem(id, email) {
    return createData(id, email, getOpenButton(id, email));
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeModal() {
    setIsDeleteModalOpen(false);
  }

  async function deleteUser() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      email: id,
      token: token,
      userId: selectedUserId
    };
    let ret = await Api.deleteSqlUser(data);
    if (ret !== false) {
      alert("L'utilisateur a été supprimé avec succès");
      window.location.reload();
    }
    else {
      alert("Une erreur est survenue.");
    }
  }

  async function getUserList() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      email: id,
      token: token,
    };
    let ret = await Api.listSqlUser(data);
    if (ret !== false) {
      ret = ret.results;
      let tmp = [];
      for (let i = 0; i < ret.length; i++) {
        console.log(ret[i])
        tmp.push(createRowItem(ret[i].id, ret[i].email));
      }
      setRows(tmp);
    }
    else {
      alert("Une erreur est survenue.");
    }
  }

  function handleNewUserEmail(value) {
    setNewUserEmail(value);
  }

  async function addUser() {
    if (newUserEmail === "") {
      alert('Merci de replir le champs email');
    }
    else {
      const token = LocalStorage.getToken();
      const id = LocalStorage.getId();
      const data = {
        email: id,
        token: token,
        userEmail: newUserEmail
      };
      let ret = await Api.addNewSqlUser(data);
      if (ret !== false) {
        alert("L'utilisateur a été ajouté avec succès");
        window.location.reload();
      }
      else {
        alert("Une erreur est survenue.");
      }
    }
  }

  return (
    <Paper sx={{width: "100%", margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography color="text.secondary" align="right" style={{fontWeight: "bold"}}>Liste des utilisateurs</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <TableContainer component={Paper} style={{maxHeight: "70vh"}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{fontWeight: "bold"}}>Id</TableCell>
              <TableCell align="left" style={{fontWeight: "bold"}}>Email</TableCell>
              <TableCell align="right" style={{fontWeight: "bold"}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              typeof rows !== "undefined"
              ?
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={index % 2 === 0 ? {backgroundColor: "#DCDCDC"} : {backgroundColor: "white"}}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="right">{row.icon}</TableCell>
                  </TableRow>
              ))
              :
              null
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Utilisateurs par page"
      />
      <div style={{margin: "20px", display: "flex", flexDirection: "column"}}>
        <p style={{fontWeight: "bold"}}>Ajouter un utilisateur</p>
        <TextField id="standard-basic" label="Email" variant="standard" style={{width: "350px"}} onChange={(e) => handleNewUserEmail(e.target.value)}/>
        <Button style={{fontWeight: "bold", width: "350px", marginTop: "0.5em", border: "1px solid lightgray"}} onClick={() => addUser()}>Valider</Button>
      </div>
      <ConfirmationModal open={isDeleteModalOpen} closeModal={closeModal} deleteUser={deleteUser} email={email}/>
    </Paper>
  );
}

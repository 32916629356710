import React, { useEffect, useState  } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import * as LocalStorage from '../services/localStorage.js';
import * as Api from "../services/quotationApi.js";

function createData(
  date: string,
  email: string,
  os: string,
  browser: string,
) {
  return {date, email, os, browser};
}

export default function Content() {
  const [rows, setRows] = React.useState([]);
  const [selectedUserId, setSelectedUserId] = React.useState(-1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  useEffect( async () => {
    getUsersConnections();
  }, []);



  function createRowItem(date, email, os, browser) {
    return createData(date, email, os, browser);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  async function getUsersConnections() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      email: id,
      token: token,
      period: "all"
    };
    const ret = await Api.getLogs(data);
    if (ret !== false) {
      let tmp = [];
      for (let i = 0; i < ret.data.length; i++) {
        tmp.push(createRowItem(ret.data[i].date, ret.data[i].email, ret.data[i].os, ret.data[i].browser));
      }
      setRows(tmp);
    }
    else {
      alert("Une erreur est survenue.");
    }
  }

  return (
    <Paper sx={{ maxWidth: 1300, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography color="text.secondary" align="right" style={{fontWeight: "bold"}}>Liste des connexions utilisateurs</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <TableContainer component={Paper} style={{maxHeight: "70vh"}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{fontWeight: "bold"}}>Date</TableCell>
              <TableCell align="left" style={{fontWeight: "bold"}}>Email</TableCell>
              <TableCell align="left" style={{fontWeight: "bold"}}>OS</TableCell>
              <TableCell align="left" style={{fontWeight: "bold"}}>Navigateur</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              typeof rows !== "undefined"
              ?
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={index % 2 === 0 ? {backgroundColor: "#DCDCDC"} : {backgroundColor: "white"}}>
                    <TableCell align="left">{row.date}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.os}</TableCell>
                    <TableCell align="left">{row.browser}</TableCell>
                  </TableRow>
              ))
              :
              null
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Connexions par page"
      />
    </Paper>
  );
}

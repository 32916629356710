import React, { useState, useEffect  } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../assets/img/logo.png';
import { useNavigate } from "react-router-dom";
import * as Api from '../services/userApi.js';
import * as LocalStorage from '../services/localStorage';
import LinearProgress from '@mui/material/LinearProgress';

const theme = createTheme();

export default function NewPassword() {
  const navigate = useNavigate();

  function testPassword(passwd, conf) {
    if (passwd.length < 8) {
      alert("Votre mot de passe doit faire au moins 8 caractères de long");
      return false;
    }

    if (passwd === "" || conf === "") {
      alert("Merci de renseigner l'ensemble des champs du formulaire");
      return false;
    }
    if (passwd !== conf) {
      alert("Les mots de passes ne correspondent pas");
      return false;
    }
    let add = 0;
    let specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
    for (let i = 0; i < specialChars.length; i++) {
      if (passwd.indexOf(specialChars[i]) > -1) {
        add = 1;
      }
    }
    if (add === 0) {
      alert("Votre mot de passe doit comptenir au moins un caractère spécial");
      return false;
    }
    add = 0;
    specialChars = "0123456789";
    for (let i = 0; i < specialChars.length; i++) {
      if (passwd.indexOf(specialChars[i]) > -1) {
        add = 1;
      }
    }
    if (add === 0) {
      alert("Votre mot de passe doit comptenir au moins un chiffre");
      return false;
    }
    add = 0;
    for (let i = 0; i < passwd.length; i++) {
      let tmp = passwd[i];
      if (tmp.toUpperCase() === tmp && tmp !== tmp.toLowerCase()) {
        add = 1;
      }
    }
    if (add === 0) {
      alert("Votre mot de passe doit comptenir au moins un caractère en majuscule");
      return false;
    }
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const credentials = new FormData(event.currentTarget);
    /*get user email addr*/
    const array = window.location.href.split('/');
    const email = array[4];
    const token = array[5];
    const data = {
      email: email,
      token: token,
      password: credentials.get('password'),
      passwordConfirmation: credentials.get('passwd2')
    }
    const ret = await Api.newPassword(data);
    if (ret === true) {
      alert("Votre mot de passe a bien été mis à jour");
      navigate('/connexion');
    }
    else {
      alert("Une erreur est survenue. Merci de réésayer plus tard");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" style={{border: "1px solid black", marginTop: "10%", borderRadius: "5%"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'black', height: "100px", width: "100px"}}>
            <img alt="logo" src={Logo} style={{width: "100px", height: "auto"}}/>
          </Avatar>
          <Typography component="h1" variant="h6" style={{marginTop: "0.5em"}}>
            <div style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
              <span style={{fontWeight: "bold"}}>Back-Office</span>
              <span>Changement de mot de passe</span>
            </div>
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="passwd2"
              label="Confirmation du mot de passe"
              type="password"
              id="passwd2"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Changer le mot de passe
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

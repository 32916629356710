import React, { useEffect, useState  } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from '@mui/material/TablePagination';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

function getCmdStatus(cmdStatus) {
  switch (cmdStatus) {
    case "Brouillon":
      return 1;
      break;
    case "En attente de validation":
      return 2;
      break;
    case "En attente de diffusion":
      return 3;
      break;
    case "En cours de diffusion":
      return 4;
      break;
    case "Terminée":
      return 5;
      break;
    default:
      return 0;
  }
}

//used to set each cmd details
function createData(cmdId: string, client: string, cmdDate: string, cmdStatus: string, order) {
  return {cmdId, client, cmdDate, cmdStatus,
    history: [
      {
        title: 'Nom de la marque',
        value: order.commercialName.length > 0 ? order.commercialName : "",
      },
      {
        title: 'Nom du produit',
        value: order.productName.length > 0 ? order.productName : "Non défini",
      },
      {
        title: 'Objectif de communication',
        value: order.fk_communicationObjective.length > 0 ? order.fk_communicationObjective : "",
      },
      {
        title: 'Zone géographique',
        value: order.geoArea.length > 0 ? order.geoArea : "",
      },
      {
        title: 'Ciblage Socio Démo',
        value: (order.fk_targetSex.length > 0 && order.fk_targetSexAge > 0) ? order.fk_targetSex + " " + order.fk_targetSexAge : order.fk_targetSex.length > 0 ? order.fk_targetSex : "",
      },
      {
        title: 'Ciblage composition du foyer',
        value: order.fk_targetHouseholdComposition.length > 0 ? order.fk_targetHouseholdComposition : "",
      },
      {
        title: 'Ciblage CSP',
        value: order.fk_targetCSP.length > 0 ? order.fk_targetCSP : "",
      },
      {
        title: 'Ciblage Conso TV',
        value: order.fk_targetConsoTV.length > 0 ? order.fk_targetConsoTV : "",
      },
      {
        title: 'Ciblage Data',
        value: order.fk_targetCSP.length > 0 ? order.fk_targetCSP : "",
      },
      {
        title: 'Ciblage Geolife',
        value: order.fk_targetGeolife.length > 0 ? order.fk_targetGeolife : "",
      },
      {
        title: "Ciblage Type d'habitat",
        value: order.fk_targetHousingType.length > 0 ? order.fk_targetHousingType : "",
      },
      {
        title: 'Ciblage intérêts',
        value: order.fk_targetInterest.length > 0 ? order.fk_targetInterest : "",
      },
      {
        title: 'Ciblage moment marketing',
        value: order.fk_targetMomentMarketing.length > 0 ? order.fk_targetMomentMarketing : "",
      },
      {
        title: 'Type de campagne',
        value: order.fk_campainType > 0 ? order.fk_campainType : "",
      },
      {
        title: 'Période de communication',
        value: (order.startDate.length > 0 && order.stopDate.length) ? "Du " + order.startDate + " au " + order.stopDate: "",
      },
      {
        title: 'Budget de la campagne',
        value: order.budget.length > 0 ? order.budget : "",
      },
      {
        title: 'Spot TV',
        value: (order.spotTvAlreadyAvailable !== -1 && order.fk_spotDuration !== 0) ? "L'utilisateur dispose de son spot TV" : (order.spotTvAlreadyAvailable === -1 && order.fk_spotDuration !== 0) ? "L'utilisateur ne dispose pas de son spot TV" : "",
      },
      {
        title: 'Spot Id',
        value: order.spotId !== 0 && order.spotId !== "0" ? order.spotId : "",
      },
      {
        title: 'Duréé du spot',
        value: order.fk_spotDuration !== 0 ? order.fk_spotDuration + " secondes" : "",
      },
    ],
  };
}


function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedCmd, setSelectedCmd] = React.useState(null);
  const [selectedCmdStatus, setSelectedCmdStatus] = React.useState(0);
  const [oldStatus, setOldStatus] = React.useState(null);

  function handleSelectedCmd(id, status, oldStatus) {
    setOldStatus(oldStatus);
    setSelectedCmd(id);
    setSelectedCmdStatus(status);
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{backgroundColor: props.color}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.cmdId}
        </TableCell>
        <TableCell align="right">{row.client}</TableCell>
        <TableCell align="right">{row.cmdDate}</TableCell>
        <TableCell align="right">{row.cmdStatus}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "1em"}}>
                <Typography variant="h6" gutterBottom component="div">
                  Détails de la commande {row.cmdId}
                </Typography>
                <Button href='' color="error" style={{fontWeight: "bold"}} onClick={() => props.openDeleteModal(row.cmdId)}>Supprimer la commande</Button>
              </div>
              <Table size="small" aria-label="cmd">
                <TableBody>
                  {row.history.map((historyRow) => (
                    historyRow.value !== ""
                    ?
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        <span style={{fontWeight: "bold"}}>{historyRow.title}</span>
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.value}
                      </TableCell>
                    </TableRow>
                    :
                    null
                  ))}
                </TableBody>
                <div style={{display: "flex", flexDirection: "column", marginTop: "1.5em", marginTop: "1em", marginBottom: "1em"}}>
                  <Typography variant="h7" gutterBottom component="div">
                    <span style={{fontSize: "1.2em"}}>Modifier le status de la commande</span>
                  </Typography>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <FormControl sx={{ m: 1, width: '15em' }} size="small">
                      <NativeSelect
                        onChange={(e) => handleSelectedCmd(row.cmdId, e.target.value, row.cmdStatus)}
                        defaultValue={getCmdStatus(row.cmdStatus)}
                        inputProps={{
                          name: '',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option value={1}>Brouillon</option>
                        <option value={2}>En attente de validation</option>
                        <option value={3}>En attente de diffusion</option>
                        <option value={4}>En cours de diffusion</option>
                        <option value={5}>Terminée</option>
                      </NativeSelect>
                    </FormControl>
                    <Button variant="contained" style={{width: "15em", marginLeft: "5px"}} onClick={() => props.openModal(selectedCmd, selectedCmdStatus, oldStatus)}>Valider</Button>
                  </div>

                </div>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  //console.log(props.orders)

  useEffect(() => {
    if (typeof props.orders !== "undefined" && props.orders) {
      let tmp = [];
      for (let i = 0; i < props.orders.length; i++) {
        tmp[i] = createData(props.orders[i].Id, props.orders[i].fk_ownerId, props.orders[i].cmdDate, props.orders[i].fk_status, props.orders[i]);
      }
      setRows(tmp);
    }
  }, props.orders);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function sortOrdersBy(id) {
    props.handleCmdRequest(id);
  }

  function checkIfUserIsAlreayIntoArray(user, array) {
    for (let i = 0; i < array.length; i++) {
      if (user === array[i]) {
        return true;
      }
    }
    return false;
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "red",
  },
}));

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ height: "60vh" }} >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell><UnfoldMoreIcon style={{cursor: "pointer"}} onClick={() => props.changeOrder(props.index)} /></StyledTableCell>
              <StyledTableCell style={{cursor: "pointer"}} onClick={() => sortOrdersBy('cmdId')}>Commade ID</StyledTableCell>
              <StyledTableCell align="right" style={{cursor: "pointer"}} onClick={() => sortOrdersBy('client')}>Client</StyledTableCell>
              <StyledTableCell align="right" style={{cursor: "pointer"}} onClick={() => sortOrdersBy('cmdDate')}>Date de la commade</StyledTableCell>
              <StyledTableCell align="right" style={{cursor: "pointer"}} onClick={() => sortOrdersBy('status')}>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              typeof rows !== "undefined"
              ?
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                <Row key={row.cmdId} row={row} color={index % 2 === 0 ? "#F5F5F5" : "#DCDCDC"} {...props}/>
              ))
              :
              null
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Commandes par page"
      />
    </Paper>
  );
}

import React, { useState, useEffect  } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import Link from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SQLUserTable from "./SQLUserTable.js";

const isDev = true;

export default function Content() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };



  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Paper sx={{ maxWidth: 1300, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography color="text.secondary" align="right" style={{fontWeight: "bold"}}>Gestion base de données SQL</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {

        <div>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Utilisateurs" {...a11yProps(0)} />
                <Tab label="Commandes" {...a11yProps(1)} />
                <Tab label="Logs" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <div value={value} index={0} style={value === 0 ? {display: "flex"} : {display: "none"}}>
              <SQLUserTable />
            </div>
            <div value={value} index={1} style={value === 1 ? {display: "flex"} : {display: "none"}}>
              <p>Commandes</p>
            </div>
            <div value={value} index={2} style={value === 2 ? {display: "flex"} : {display: "none"}}>
              <p>Logs</p>
            </div>
          </Box>
        </div>
      }
    </Paper>
  );
}

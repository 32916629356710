import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import UpdateUserModal from './updateUserModal.js';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30vw",
  minWidth: '550px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal(props) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={props.closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            {
              <span className="bold">Modifier utilisateur</span>
            }
            </Typography>
            <form id="" className="" onSubmit={e => e.preventDefault()}>
              <div style={{marginBottom: '1em', marginTop: "1.5em"}}>
                <FormControl fullWidth>
                  <InputLabel id="">Type d'utilisateur</InputLabel>
                  <Select
                    labelId=""
                    id=""
                    value={props.userType}
                    label="Type d'utilisateur"
                    onChange={(e) => props.handleUserTypeChange(e.target.value)}
                    style={{marginBottom: "0.5em"}}
                  >
                    <MenuItem value={0}>Annonceur</MenuItem>
                    <MenuItem value={1}>Agence</MenuItem>
                  </Select>
                <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                  <TextField style={{width: "100%"}} id="" value={props.company} placeholder="Société" label="Société" onChange={(e) => props.updateState("company", e.target.value)}/>
                </div>
                <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                  <TextField style={{width: "100%"}} id="" placeholder="Nom" label="Nom" value={props.lastName} onChange={(e) => props.updateState("lastName", e.target.value)}/>
                </div>
                <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                  <TextField style={{width: "100%"}} id="" placeholder="Prénom" label="Prénom" value={props.firstName} onChange={(e) => props.updateState("firstName", e.target.value)}/>
                </div>
                <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                  <TextField style={{width: "100%"}} id="" placeholder="Email" label="Email" value={props.email} onChange={(e) => props.updateState("email", e.target.value)}/>
                </div>
                <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                  <TextField style={{width: "100%"}} id="" placeholder="Téléphone" label="Téléphone" value={props.phoneNumber} onChange={(e) => props.updateState("phoneNumber", e.target.value)}/>
                </div>
                <div style={{marginTop: "0.5em", marginBottom: '0.5em'}}>
                  <TextField style={{width: "100%"}} id="" placeholder="Adresse" label="Adresse" value={props.addr} disabled={true}/>
                </div>
                <div style={{marginTop: "0.5em", marginBottom: '0.5em', display: "flex", justifyContent: "space-between"}}>
                  <Button color="error" onClick={props.openDeleteModal}>Supprimer l'utilisateur</Button>
                  <Button onClick={props.openConfirmModal}>Modifier</Button>
                </div>
              </FormControl>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React, { useState, useEffect  } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

const lineWidth = 60;

export default function Content(props) {
  const [hovered, setHovered] = React.useState(undefined);
  return (
    <div style={{width: "100%", height: "60vh", display: "flex", flexWrap: "wrap", justifyContent: "space-around", marginTop: "1em"}}>
      <div style={{width: "30%", height: "35vh", border: "1px solid lightgray", paddingLeft: "5px", paddingRight: "5px", flexDirection: "column", position: "relative", maxHeight: "200px"}}>
        <p style={{textAlign: "center", fontSize: "0.9em"}}>Nombre de connexions</p>
        <div style={{display: "flex", justifyContent: "center"}}>
          <p style={{fontSize: "2.5em", fontWeight: "bold", position: "absolute", bottom: "1px"}}>{props.data.connections}</p>
        </div>
      </div>
      <div style={{width: "30%", height: "35vh", border: "1px solid lightgray", paddingLeft: "5px", paddingRight: "5px", flexDirection: "column", position: "relative", maxHeight: "200px"}}>
        <p style={{textAlign: "center", fontSize: "0.9em"}}>Nombre de connexions uniques</p>
        <div style={{display: "flex", justifyContent: "center"}}>
          <p style={{fontSize: "2.5em", fontWeight: "bold", position: "absolute", bottom: "1px"}}>{props.data.uniqueConnections}</p>
        </div>
      </div>
      <div style={{width: "30%", height: "35vh", border: "1px solid lightgray", paddingLeft: "5px", paddingRight: "5px", flexDirection: "column", position: "relative", maxHeight: "200px"}}>
        <p style={{textAlign: "center", fontSize: "0.9em"}}>Nombre de commandes</p>
        <div style={{display: "flex", justifyContent: "center"}}>
          <p style={{fontSize: "2.5em", fontWeight: "bold", position: "absolute", bottom: "1px"}}>{props.data.cmd}</p>
        </div>
      </div>
      <div style={{width: "30%", height: "35vh", border: "1px solid lightgray", paddingLeft: "5px", paddingRight: "5px", flexDirection: "column", position: "relative", maxHeight: "200px"}}>
        <p style={{textAlign: "center", fontSize: "0.9em"}}>Nombre de brouillons</p>
        <div style={{display: "flex", justifyContent: "center"}}>
          <p style={{fontSize: "2.5em", fontWeight: "bold", position: "absolute", bottom: "1px"}}>{props.data.draftCmd}</p>
        </div>
      </div>
      <div style={{width: "30%", height: "35vh", border: "1px solid lightgray", paddingLeft: "5px", paddingRight: "5px", flexDirection: "column", position: "relative", maxHeight: "200px"}}>
        <p style={{textAlign: "center", fontSize: "0.9em"}}>Navigateurs</p>
        <PieChart
          data={props.data.browserArray}
          style={{height: "130px"}}
          lineWidth={60}
          animate
          onMouseOver={(_, index) => {
            setHovered(index);
          }}
          onMouseOut={() => {
            setHovered(undefined);
          }}
        />
      </div>
      <div style={{width: "30%", height: "35vh", border: "1px solid lightgray", paddingLeft: "5px", paddingRight: "5px", flexDirection: "column", position: "relative", maxHeight: "200px"}}>
        <p style={{textAlign: "center", fontSize: "0.9em"}}>OS</p>
        <PieChart
          data={props.data.osArray}
          style={{height: "130px"}}
          lineWidth={60}
          animate
          onMouseOver={(_, index) => {
            setHovered(index);
          }}
          onMouseOut={() => {
            setHovered(undefined);
          }}
        />
      </div>
    </div>
  );
}
